import { Box, HStack, IconButton, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Text } from '@chakra-ui/react';
import copy from 'copy-to-clipboard';
import { FaLink, FaQrcode, FaPen, FaRegTrashAlt } from 'react-icons/fa';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';

import { Poll } from '@customTypes/Poll';
import { api } from '@services/api';

type PollButtonsStackProps = {
  poll: Poll;
};

export const PollButtonsStack = ({ poll }: PollButtonsStackProps) => {
  const navigate = useNavigate();
  const deleteModal = useDisclosure();
  const qrCodeModal = useDisclosure();

  const handleDelete = () => {
    if (poll.uuid) {
      api.delete(`/polls/${poll.uuid}`)
        .then(() => {
          navigate('/admin/votacoes');
          deleteModal.onClose();
        });
    }
  };

  const handleClose = (modal: { onClose: () => void }) => {
    modal.onClose();
  };

  return (
    <>
      <HStack spacing={4}>
        <IconButton
          variant="outline"
          color="white"
          aria-label="Copiar link da votação"
          fontSize="20px"
          _hover={{ bg: 'gray.900', transform: 'scale(1.1)' }}
          icon={<FaLink />}
          onClick={() => {
            copy(`${window.location.origin}/votacoes/${poll.uuid}`);
          }}
        />
        <IconButton
          variant="outline"
          color="white"
          aria-label="Mostrar QRCode da votação"
          fontSize="20px"
          _hover={{ bg: 'gray.900', transform: 'scale(1.1)' }}
          icon={<FaQrcode />}
          onClick={() => {
            qrCodeModal.onOpen();
          }}
        />
        <IconButton
          variant="outline"
          color="white"
          aria-label="Editar votação"
          fontSize="20px"
          _hover={{ bg: 'gray.900', transform: 'scale(1.1)' }}
          icon={<FaPen />}
          onClick={() => navigate(`/admin/votacoes/${poll.uuid}/editar`)}
        />
        <IconButton
          variant="outline"
          color="white"
          aria-label="Deletar votação"
          fontSize="20px"
          _hover={{ bg: 'gray.900', transform: 'scale(1.1)' }}
          icon={<FaRegTrashAlt />}
          onClick={() => {
            deleteModal.onOpen();
          }}
        />
      </HStack>

      <Modal isOpen={deleteModal.isOpen} onClose={deleteModal.onClose}>
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white">
          <ModalHeader>Excluir Votação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Tem certeza que deseja excluir a votação <b>{poll.title}</b>?</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDelete}>
              Excluir
            </Button>
            <Button onClick={() => handleClose(deleteModal)}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={qrCodeModal.isOpen} onClose={qrCodeModal.onClose} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white">
          <ModalHeader>{poll.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" justifyContent="center" p="8">
            <Box h="auto" m="0 auto" maxW="100%" w="100%">
              <QRCode value={`${window.location.origin}/votacoes/${poll.uuid}`}
                size={512}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                viewBox={'0 0 512 512'} />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
