import { useEffect, useState } from 'react';

export const usePageTitle = (newTitle: string) => {
  const [title, setTitle] = useState(newTitle);

  useEffect(() => {
    document.title = title + ' - Primeira';

    return () => {
      document.title = 'Primeira Igreja Presbiteriana de Campo Grande';
    };
  }, [title]);

  return { title, setTitle };
};
