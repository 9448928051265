import { HStack, IconButton, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Text } from '@chakra-ui/react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { User } from '@customTypes/User';
import { api } from '@services/api';

type UserButtonsStackProps = {
  user: User;
};

export const UserIconButtonsStack = ({ user }: UserButtonsStackProps) => {
  const navigate = useNavigate();
  const deleteModal = useDisclosure();

  const handleDelete = () => {
    if (user.username) {
      api.delete(`/users/${user.username}`)
        .then(() => {
          navigate('/admin/votacoes');
          deleteModal.onClose();
        });
    }
  };

  const handleClose = (modal: { onClose: () => void }) => {
    modal.onClose();
  };

  return (
    <>
      <HStack spacing={4}>
        <IconButton
          variant="outline"
          color="white"
          aria-label="Deletar votação"
          fontSize="20px"
          _hover={{ bg: 'gray.900', transform: 'scale(1.1)' }}
          icon={<FaRegTrashAlt />}
          onClick={() => {
            deleteModal.onOpen();
          }}
        />
      </HStack>

      <Modal isOpen={deleteModal.isOpen} onClose={deleteModal.onClose}>
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white">
          <ModalHeader>Excluir usuário</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Tem certeza que deseja excluir o usuário <b>{user.name}</b>?</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDelete}>
              Excluir
            </Button>
            <Button onClick={() => handleClose(deleteModal)}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
