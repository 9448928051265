import {
  Button,
  chakra,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useToast
} from '@chakra-ui/react';
import { FormEventHandler } from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { PasswordInput } from '@atoms/Input/PasswordInput';
import { useAuth } from '@contexts/auth';
import { usePageTitle } from '@hooks/usePageTitle';
import { AuthTemplate } from '@templates/AuthTemplate';

export const Login = () => {
  usePageTitle('Login');

  const { login, isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  const CFaUserAlt = chakra(FaUserAlt);

  if (isAuthenticated) {
    const from = location.state?.from?.pathname || '/';
    navigate(from, { replace: true });
  }

  const handleSubmit: FormEventHandler<HTMLDivElement> = async (event) => {
    event.preventDefault();

    const { target } = event;

    const loginData = {
      email: (target as HTMLFormElement).email.value,
      password: (target as HTMLFormElement).password.value
    };

    try {
      await login(loginData);
    } catch (error) {
      console.error(error);
      toast({
        title: 'Erro ao fazer login',
        description: (error as Error).message,
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  };

  return (
    <AuthTemplate handleSubmit={handleSubmit}>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between">
        <Text fontSize="lg" fontWeight="300">
            Bem-vindo à Primeira
        </Text>
        <Link to="/registrar">
          <Text fontSize="lg" fontWeight="300" color="blue.500">
              Cadastre-se
          </Text>
        </Link>
      </Flex>
      <Heading fontSize="5xl" mb="2rem">Login</Heading>

      <FormControl>
        <FormLabel fontSize="md" fontWeight="300">E-mail</FormLabel>
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <CFaUserAlt color='grey' />
          </InputLeftElement>
          <Input type='email' placeholder='Email' name='email' />
        </InputGroup>
      </FormControl>

      <FormControl>
        <FormLabel fontSize="md" fontWeight="300">Senha</FormLabel>
        <PasswordInput />
        <FormHelperText textAlign="right">
          <Link to="/">esqueceu a senha?</Link>
        </FormHelperText>
      </FormControl>

      <Button colorScheme="teal" type="submit" w="full">Entrar</Button>
    </AuthTemplate>
  );
};
