import { Box, Image, Link, chakra } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { Serie } from '@customTypes/Serie';

type SerieCardProps = {
  serie: Serie;
};

export const SerieCard = ({ serie }: SerieCardProps) => {
  const boxMotion = {
    rest: {
      scale: 1
    },
    hover: {
      scale: 1.1
    }
  };

  const detailsMotion = {
    rest: {
      height: '0',
      padding: '0'
    },
    hover: {
      height: '5rem',
      padding: '10px'
    }
  };

  return (
    <Box
      as={motion.div}
      w="xs"
      bg="#1e1f26"
      shadow="lg"
      rounded="lg"
      overflow="hidden"
      mx="auto"
      pos="relative"
      initial="rest"
      whileHover="hover"
      animate="rest"
      variants={boxMotion}
    >
      <Image
        w="full"
        h={44}
        fit="cover"
        src={serie.thumbnail}
        alt="avatar"
      />

      <Box
        as={motion.div}
        textAlign="center"
        layout
        variants={detailsMotion}
      >
        <Link
          display="block"
          fontSize="xl"
          color="white"
          fontWeight="bold"
        >
          {serie.title}
        </Link>
        <chakra.span
          fontSize="sm"
          color="gray.400"
        >
          {String(serie.publishedAt)}
        </chakra.span>
      </Box>
    </Box>
  );
};
