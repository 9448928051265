import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { api } from '@services/api';

export const Download = () => {
  const { file } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/download/${file}`, {
      responseType: 'blob'
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      navigate(url);
    });
  }, []);

  return (
    <div />
  );
};
