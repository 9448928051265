import { usePageTitle } from '@hooks/usePageTitle';
import { DayCard } from '@molecules/Card/';
import { DefaultHeader } from '@organisms/Header';
import { HeadingSlider } from '@organisms/HeadingSlider';
import { HomeCardList } from '@organisms/HomeCardList';

export const Home = () => {
  usePageTitle('Home');

  return (
    <>
      <DefaultHeader />
      <HeadingSlider />
      <HomeCardList />
      <DayCard />
    </>
  );
};
