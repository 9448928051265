import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Download } from '@atoms/Download';
import { ErrorPage } from '@pages/ErrorPage';
import { Home } from '@pages/Home';
import { Login } from '@pages/Login';
import { Logout } from '@pages/Logout';
import { Register } from '@pages/Register';

import { adminRoutes } from './admin';
import { pollsRoutes } from './polls';

export const Router = () => {
  const router = createBrowserRouter([
    {
      index: true,
      element: <Home />,
      errorElement: <ErrorPage />
    },
    {
      path: 'login',
      element: <Login />
    },
    {
      path: 'logout',
      element: <Logout />
    },
    {
      path: 'registrar',
      element: <Register />
    },
    {
      path: 'download/:file',
      element: <Download />
    },
    adminRoutes(),
    pollsRoutes()
  ]);

  return (
    <RouterProvider router={router} />
  );
};
