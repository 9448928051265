import {
  Box,
  Flex,
  IconButton,
  Collapse,
  Icon,
  useDisclosure,
  Grid,
  chakra
} from '@chakra-ui/react';
import { IoClose, IoMenu } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import { Logo } from '@atoms/Logo';
import { DesktopNav, MobileNav } from '@molecules/Nav';

export const DefaultHeader = () => {
  const { isOpen, onToggle } = useDisclosure();
  const CLink = chakra(Link);

  return (
    <Box>
      <Flex
        bg={{ base: 'neutral.900', md: '#1A1A1AA0' }}
        backdropFilter="blur(0.3rem)"
        pos={{ md: 'fixed' }}
        w="100vw"
        h={{ base: '4rem', md: '5rem' }}
        color="white"
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor="#aaaaaa33"
        align={'center'}
        zIndex={50}>
        <Grid
          flex={{ base: 1 }}
          gridTemplateAreas="'leftNav logo rightNav'"
          gridTemplateColumns="1fr 5rem 1fr"
          h="4rem">
          <CLink
            gridArea="logo"
            to="/"
            display="flex"
            justifyContent="center"
            alignItems="center">
            <Logo w={{
              base: '2.5rem',
              md: '3rem'
            }} />
          </CLink>

          <DesktopNav />

          <Flex
            gridArea={{ base: 'rightNav', md: '' }}
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', md: 'none' }}
            justify="flex-end"
            align="center">
            <IconButton
              onClick={onToggle}
              bg={'transparent'}
              _hover={{ bg: 'transparent' }}
              icon={
                isOpen
                  ? <Icon as={IoClose} w={5} h={5} />
                  : <Icon as={IoMenu} w={5} h={5} />
              }
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
        </Grid>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
};
