import { DashboardCard } from '@molecules/Card/DashboardCard';

export const Admin = () => {
  return (
    <>
      <DashboardCard>
        Admin
      </DashboardCard>
      <DashboardCard>
        Admin
      </DashboardCard>
    </>
  );
};
