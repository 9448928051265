import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  Text,
  useToast
} from '@chakra-ui/react';
import { FormEventHandler } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { PasswordInput } from '@atoms/Input/PasswordInput';
import { useAuth } from '@contexts/auth';
import { usePageTitle } from '@hooks/usePageTitle';
import { AuthTemplate } from '@templates/AuthTemplate';

export const Register = () => {
  usePageTitle('Registrar');

  const { register, isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  if (isAuthenticated) {
    const from = location.state?.from?.pathname || '/';
    navigate(from, { replace: true });
  }

  const handleSubmit: FormEventHandler<HTMLDivElement> = async (event) => {
    event.preventDefault();

    const { target } = event;

    const loginData = {
      name: (target as HTMLFormElement).regName.value,
      username: (target as HTMLFormElement).username.value,
      email: (target as HTMLFormElement).email.value,
      password: (target as HTMLFormElement).password.value
    };

    try {
      await register(loginData);
    } catch (error) {
      console.error(error);
      toast({
        title: 'Erro ao registrar',
        description: (error as Error).message,
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  };

  return (
    <AuthTemplate handleSubmit={handleSubmit}>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between">
        <Text fontSize="lg" fontWeight="300">
            Bem-vindo à Primeira
        </Text>
        <Link to="/login">
          <Text fontSize="lg" fontWeight="300" color="blue.500">
              Login
          </Text>
        </Link>
      </Flex>
      <Heading fontSize="5xl" mb="2rem">Registrar</Heading>

      <FormControl>
        <FormLabel fontSize="md" fontWeight="300">Nome</FormLabel>
        <InputGroup>
          <Input type='text' placeholder='Nome' name='regName' />
        </InputGroup>
      </FormControl>

      <FormControl>
        <FormLabel fontSize="md" fontWeight="300">Nome de usuário</FormLabel>
        <InputGroup>
          <Input type='text' placeholder='Nome de usuário' name='username' />
        </InputGroup>
      </FormControl>

      <FormControl>
        <FormLabel fontSize="md" fontWeight="300">E-mail</FormLabel>
        <InputGroup>
          <Input type='email' placeholder='Email' name='email' />
        </InputGroup>
      </FormControl>

      <FormControl>
        <FormLabel fontSize="md" fontWeight="300">Senha</FormLabel>
        <PasswordInput hideIcon />
      </FormControl>

      <Button colorScheme="teal" type="submit" w="full" mt={8}>Entrar</Button>
    </AuthTemplate>
  );
};
