import { createContext, ReactNode, useContext, useMemo } from 'react';

import { User } from '@customTypes/User';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { api } from '@services/api';

type AuthData = {
  email: string;
  password: string;
};

type RegisterData = AuthData & {
  name: string;
  username: string;
};

type AuthProviderProps = {
  children: ReactNode;
};

type AuthContextData = {
  user: User;
  isAuthenticated: boolean;
  register: (data: RegisterData) => Promise<void>;
  login: (data: AuthData) => Promise<void>;
  logout: () => Promise<void>;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser, removeUser] = useLocalStorage('@Primeira:user', null);
  const [, setToken, removeToken] = useLocalStorage('@Primeira:token', '');

  const register = async (data: RegisterData) => {
    const response = await api.post('/register', data);

    setUser(response.data.user);
    setToken(response.data.token);
  };

  const login = async (loginData: AuthData) => {
    const response = await api.post('/login', loginData)
      .then((response) => response.data)
      .catch((error) => {
        const authError = new Error();

        if (error.response.status === 401) {
          authError.message = 'Usuário ou senha inválidos';
        }

        throw authError;
      });

    const { user: userData, accessToken } = response;

    // api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    api({
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    setUser(userData);
    setToken(accessToken);
  };

  const logout = async () => {
    removeUser();
    return api.post('/logout').then(() => removeToken());
  };

  const value = useMemo<AuthContextData>(
    () => ({
      user,
      isAuthenticated: !!user,
      register,
      login,
      logout
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
