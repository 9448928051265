import {
  Flex,
  chakra
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { Logo } from '@atoms/Logo';

export const HeaderWithoutNav = () => {
  const CLink = chakra(Link);

  return (
    <Flex
      bg={{ base: 'neutral.900' }}
      w="100vw"
      h={{ base: '4rem', md: '5rem' }}
      justifyContent="center"
      py={{ base: 2 }}
      px={{ base: 4 }}
      borderBottom={1}
      borderStyle={'solid'}
      borderColor="#aaaaaa33"
      zIndex={50}>
      <CLink
        gridArea="logo"
        to="/"
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Logo w={{
          base: '2.5rem',
          md: '3rem'
        }} />
      </CLink>
    </Flex>
  );
};
