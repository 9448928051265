import { Stack } from '@chakra-ui/react';
import { Fragment } from 'react';

import { DesktopNavItem } from '@atoms/NavItem';

import { NavItem, NAV_ITEMS } from '.';

export const DesktopNav = () => {
  const NAV_ITEMS_LEFT: NavItem[] = NAV_ITEMS.slice(0, Math.ceil(NAV_ITEMS.length / 2));
  const NAV_ITEMS_RIGHT: NavItem[] = NAV_ITEMS.slice(Math.ceil(NAV_ITEMS.length / 2));

  return (
    <Fragment>
      <Stack
        direction={'row'}
        spacing={4}
        alignItems="center"
        justify="flex-end"
        gridArea="leftNav"
        display={{ base: 'none', md: 'flex' }}>
        {NAV_ITEMS_LEFT.map((navItem) => (
          <DesktopNavItem key={navItem.label} {...navItem} />
        ))}
      </Stack>
      <Stack
        direction={'row'}
        spacing={4}
        alignItems="center"
        justify="flex-start"
        gridArea="rightNav"
        display={{ base: 'none', md: 'flex' }}>
        {NAV_ITEMS_RIGHT.map((navItem) => (
          <DesktopNavItem key={navItem.label} {...navItem} />
        ))}
      </Stack>
    </Fragment>
  );
};
