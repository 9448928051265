import { Link, Stack } from '@chakra-ui/react';

import { NavItem } from '../Nav';

type MobileSubNavProps = {
  children: Array<NavItem>;
};

export const MobileSubNav = ({ children }: MobileSubNavProps) => {
  return (

    <Stack
      pl={8}
      py={2}
      align={'start'}
      bg="gray.900">
      {children &&
        children.map((child) => (
          <Link
            key={child.label}
            py={2}
            pl={4}
            href={child.href}
            color={'gray.200'}
            borderLeft={1}
            borderStyle={'solid'}
            borderColor={'gray.700'}>
            {child.label}
          </Link>
        ))}
    </Stack>
  );
};
