import { Heading } from '@chakra-ui/react';
import { Navigate, redirect } from 'react-router-dom';

import { useAuth } from '@contexts/auth';
import { AuthTemplate } from '@templates/AuthTemplate';

export const Logout = () => {
  const { isAuthenticated, logout } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  logout().then(() => {
    setTimeout(() => {
      redirect('/');
    }, 5000);
  });

  return (
    <AuthTemplate handleSubmit={() => {}}>
      <Heading fontSize="3xl">Deslogando...</Heading>
    </AuthTemplate>
  );
};
