import { Flex, FlexProps } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

type FadeSliderProps = FlexProps;

export const FadeSlider = (props: FadeSliderProps) => {
  const slides = [
    {
      img: 'https://images.pexels.com/photos/2599537/pexels-photo-2599537.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
      index: 0
    },
    {
      img: 'https://images.pexels.com/photos/2714581/pexels-photo-2714581.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
      index: 1
    },
    {
      img: 'https://images.pexels.com/photos/2878019/pexels-photo-2878019.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
      index: 2
    },
    {
      img: 'https://images.pexels.com/photos/1142950/pexels-photo-1142950.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
      index: 3
    },
    {
      img: 'https://images.pexels.com/photos/9356474/pexels-photo-9356474.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      index: 4
    }
  ];

  const [currentSlide, setCurrentSlide] = useState(slides[0]);
  const slidesCount = slides.length;
  const SLIDES_INTERVAL_TIME = 8000;

  useEffect(() => {
    const nextSlide = () => {
      setCurrentSlide((s) =>
        (s.index === slidesCount - 1 ? slides[0] : slides[s.index + 1]));
    };

    const automatedSlide = setInterval(() => {
      nextSlide();
    }, SLIDES_INTERVAL_TIME);
    return () => clearInterval(automatedSlide);
  }, [slidesCount]);
  return (
    <Flex
      w="full"
      h={{ base: 'calc(100vh - 4rem)', md: '100vh' }}
      alignItems="center"
      justifyContent="center"
      zIndex={-10}
      pos="absolute"
      {...props}
    >
      <Flex h="100%" w="full" overflow="hidden">
        <Flex pos="relative" h="100%" w="full">
          <AnimatePresence>
            <motion.div
              key={currentSlide.index}
              transition={{ duration: 1 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                position: 'absolute',
                left: 0,
                zIndex: -10,
                minWidth: '100%',
                minHeight: '100%',
                backgroundImage: `url(${currentSlide.img})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            />
          </AnimatePresence>
        </Flex>
      </Flex>
    </Flex>
  );
};
