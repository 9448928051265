import { Flex, IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaTimes } from 'react-icons/fa';

import { User } from '@customTypes/User';

type FileDropzoneProps = {
  users: User[];
  setUsers: (users: User[]) => void;
};

export const FileDropzone = ({ users, setUsers }: FileDropzoneProps) => {
  const onDrop = useCallback((acceptedFiles: Blob[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
      // Do whatever you want with the file contents
        const users = reader.result as string;

        const splittedUsers = users.split(/\r\n|\n/gm);

        if (splittedUsers.length === 0) return;

        const headerLine = splittedUsers.shift();

        if (!headerLine) return;

        const headers = headerLine.toLowerCase().split(',');
        const usersData = splittedUsers.map((user) => user.split(','));

        const usersObject: User[] = usersData.map((user) => ({
          name: user[headers.indexOf('name')],
          username: user[headers.indexOf('username')],
          email: user[headers.indexOf('email')],
          cellphone: user[headers.indexOf('cellphone')],
          password: user[headers.indexOf('password')]
        }));

        setUsers(usersObject);
      };
      reader.readAsText(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: { 'text/csv': ['.csv'] } });

  return (
    <>
      {users.length === 0 && (
        <Flex justify="center" align="center" w="100%" bg="whiteAlpha.100" p="8" cursor="pointer"
          borderRadius={8} border="2px dashed" borderColor="whiteAlpha.300" _hover={{ bg: 'whiteAlpha.500' }} {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Arraste um arquivo para cá</p>
        </Flex>
      )}
      {users.length > 0 && (
        <TableContainer w="100%">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>name</Th>
                <Th>username</Th>
                <Th>email</Th>
                <Th>cellphone</Th>
                <Th>password</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user) => (
                <Tr key={user.username}>
                  <Td>{user.name}</Td>
                  <Td>{user.username}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.cellphone}</Td>
                  <Td>{user.password}</Td>
                  <Td textAlign="right">
                    <IconButton aria-label="Delete user" color="red.500" size="md" bg="transparent"
                      icon={<FaTimes size="1.3rem"/>} onClick={() => setUsers(users.filter(
                        (u) => u.username !== user.username
                      ))} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
