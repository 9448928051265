import { Navigate } from 'react-router-dom';

import { useAuth } from '@contexts/auth';
import { Admin } from '@pages/admin';
import { CreatePoll, ListPolls, SelectVoters, ViewPoll } from '@pages/admin/polls';
import { ListUsers } from '@pages/admin/users';
import { CreateUsersBulk } from '@pages/admin/users/CreateUsersBulk';
import AdminTemplate from '@templates/AdminTemplate';
import { RequireAuth } from '@utils';

export const adminRoutes = (): object => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return {
      path: '/admin/*',
      element: <RequireAuth />
    };
  }

  if (user?.name !== 'Admin') {
    return {
      path: '/admin/*',
      element: <Navigate to="/" />
    };
  }

  return {
    path: 'admin',
    element: <AdminTemplate />,
    children: [
      {
        index: true,
        element: <Admin />
      },
      {
        path: 'votacoes',
        children: [
          {
            index: true,
            element: <ListPolls />
          },
          {
            path: 'nova',
            element: <CreatePoll />
          },
          {
            path: ':uuid',
            element: <ViewPoll />
          },
          {
            path: ':uuid/editar',
            element: <CreatePoll />
          },
          {
            path: ':uuid/votantes',
            element: <SelectVoters />
          }
        ]
      },
      {
        path: 'usuarios',
        children: [
          {
            index: true,
            element: <ListUsers />
          },
          {
            path: 'novo',
            element: <div>Create a new user</div>
          },
          {
            path: 'novos',
            element: <CreateUsersBulk />
          },
          {
            path: ':username',
            element: <div>View user</div>
          },
          {
            path: ':username/editar',
            element: <div>Edit user</div>
          }
        ]
      }
    ]
  };
};
