import { CircularProgress, Flex, Heading, HStack, Icon, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useLocation, useParams } from 'react-router-dom';

import { Poll, PollChoice } from '@customTypes/Poll';
import { User } from '@customTypes/User';
import { usePageTitle } from '@hooks/usePageTitle';
import { PollButtonsStack } from '@molecules/IconButtonsStack';
import { api } from '@services/api';

export const ViewPoll = () => {
  usePageTitle('Vizualizar votação');

  const { uuid } = useParams();
  const location = useLocation();

  const [poll, setPoll] = useState<Poll>({} as Poll);
  const [pollChoices, setPollChoices] = useState<PollChoice[]>([]);
  const [pollVoters, setPollVoters] = useState<User[]>([]);
  const [loading, setLoading] = useState({ choices: false, voters: false });

  useEffect(() => {
    if (location.state?.poll) {
      setPoll(location.state.poll as Poll);
    } else {
      api.get(`/polls/${uuid}`)
        .then((response) => setPoll(response.data?.data));
    }

    setLoading({ ...loading, choices: true, voters: true });

    api.get(`/polls/${uuid}/choices`)
      .then((response) => setPollChoices(response.data?.data))
      .finally(() => setLoading({ ...loading, choices: false }));

    api.get(`/polls/${uuid}/voters`)
      .then((response) => setPollVoters(response.data?.data))
      .finally(() => setLoading({ ...loading, voters: false }));
  }, [uuid]);

  return (
    <>
      <Flex
        p="2rem 2rem"
        borderRadius="1rem"
        flex={1}
        bg={'gray.900'}
        height="fill"
        direction="column"
        alignItems="stretch"
      >
        <Flex justify="space-between">
          <Heading color={'white'}>{poll.title}</Heading>
          <PollButtonsStack poll={poll} />
        </Flex>

        <Text color={'white'} mt="1rem" fontSize="1rem">{poll.description}</Text>

        <Flex position="relative" mt={8}>
          <HStack spacing={4} width="100%" display="flex">
            <Flex border="1px solid gray" flex="1" h="100%" justify="stretch" borderRadius="1rem" overflow="hidden" pos="relative">
              <UnorderedList w="100%" h={'400px'} overflowY="auto"
                listStyleType="none" m={0}>
                {pollChoices
                  .sort((a, b) => (b.votes ?? 0) - (a.votes ?? 0))
                  .map((choice) => (
                    <ListItem key={choice.id}
                      p="1rem 1.5rem"
                      color={'white'}
                      textAlign="left"
                      borderBottom="1px solid gray"
                      w="100%"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      _hover={{ bg: 'gray.700' }}
                      _last={{ borderBottom: 'none' }}
                    >
                      <Text>{choice.choice}</Text>
                      <Text fontSize="0.8rem" color="gray.400">{choice.votes} votos</Text>
                    </ListItem>
                  ))}
              </UnorderedList>
              {loading.choices && <CircularProgress isIndeterminate color="green.300" position="absolute"
                top="50%" left="50%" transform="translate(-50%, -50%)"
              />}
            </Flex>
            <Flex border="1px solid gray" flex="1" h="100%" justify="stretch" borderRadius="1rem" overflow="hidden" pos="relative">
              <UnorderedList w="100%" h={'400px'} overflowY="auto"
                listStyleType="none" m={0}>
                {pollVoters
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .sort((a, b) => (a.voted === b.voted) ? 0 : a.voted ? 1 : -1)
                  .map((selectedUser, index) => (
                    <Text key={index}
                      p="1rem 1.5rem"
                      color={'white'}
                      textAlign="left"
                      borderBottom="1px solid gray"
                      w="100%"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      _hover={{ bg: 'gray.700' }}
                      _last={{ borderBottom: 'none' }}
                    >
                      {selectedUser.name}
                      <Icon as={selectedUser.voted ? FaCheck : FaTimes} color={selectedUser.voted ? 'green.400' : 'red.400'} />
                    </Text>
                  ))}
              </UnorderedList>
              {loading.voters && <CircularProgress isIndeterminate color="green.300" position="absolute"
                top="50%" left="50%" transform="translate(-50%, -50%)"
              />}
            </Flex>
          </HStack>

        </Flex>
      </Flex>
    </>
  );
};
