import { Button, CircularProgress, Divider, Flex, Heading, HStack, ListItem, Select, Text, UnorderedList } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Poll } from '@customTypes/Poll';
import { User } from '@customTypes/User';
import { api } from '@services/api';

export const SelectVoters = () => {
  const { uuid } = useParams();

  const [loading, setLoading] = useState({ polls: false, users: false });
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [polls, setPolls] = useState<Poll[]>([]);
  const [selectedPoll, setSelectedPoll] = useState<Poll>();

  const navigate = useNavigate();

  useEffect(() => {
    setLoading({ ...loading, users: true });
    api.get('/users')
      .then((response) => setUsers((response.data?.data ?? [])
        .filter((user: User) => user.name !== 'Admin')))
      .finally(() => setLoading({ ...loading, users: false }));

    api.get('/polls')
      .then((response) => setPolls(response.data?.data ?? []));
  }, []);

  useEffect(() => {
    if (selectedPoll) {
      setLoading({ ...loading, users: true });
      api.get(`/polls/${selectedPoll.uuid}/voters`)
        .then((response) => setSelectedUsers(response.data?.data ?? []))
        .finally(() => setLoading({ ...loading, users: false }));
    }
  }, [selectedPoll]);

  return (
    <Flex
      p="2rem 2rem"
      borderRadius="1rem"
      flex={1}
      bg={'gray.900'}
      height="fill"
      direction="column"
      alignItems="stretch"
    >
      <Heading color={'white'}>Copiar votantes de:</Heading>
      <Select
        placeholder="Selecione uma votação"
        mt={8}
        bg="gray.800"
        color="white"
        _hover={{ bg: 'gray.700' }}
        _focus={{ bg: 'gray.700' }}
        _active={{ bg: 'gray.700' }}
        onChange={(event) => {
          const selectedPoll = polls.find((poll) => poll.uuid === event.target.value);
          setSelectedPoll(selectedPoll);
        }}
      >
        {polls.map((poll) => (
          <option key={poll.uuid} value={poll.uuid}>{poll.title}</option>
        ))}
      </Select>

      <Divider orientation="horizontal" my={8} />

      <Heading color={'white'}>Selecionar votantes</Heading>
      <Flex position="relative" mt={8}>
        <HStack spacing={4} width="100%" display="flex">
          <Flex border="1px solid gray" flex="1" h="100%" justify="stretch" borderRadius="1rem" overflow="hidden">
            <UnorderedList w="100%" h={'400px'} overflowY="auto"
              listStyleType="none" m={0}>
              {users
                .filter((user) =>
                  !selectedUsers
                    .find((selectedUser) => selectedUser.email === user.email))
                .sort((a, b) => a.name?.localeCompare(b.name))
                .map((user, index) => (
                  <ListItem key={index}
                    p="1rem 1.5rem"
                    color={'white'}
                    textAlign="left"
                    borderBottom="1px solid gray"
                    w="100%"
                    cursor="pointer"
                    _hover={{ bg: 'gray.700' }}
                    _last={{ borderBottom: 'none' }}
                    onClick={() => setSelectedUsers([...selectedUsers, user])}
                  >{user.name}</ListItem>
                ))}
            </UnorderedList>
          </Flex>
          <Flex border="1px solid gray" flex="1" h="100%" justify="stretch" borderRadius="1rem" overflow="hidden">
            <UnorderedList w="100%" h={'400px'} overflowY="auto"
              listStyleType="none" m={0}>
              {selectedUsers
                .sort((a, b) => a.name?.localeCompare(b.name))
                .map((selectedUser, index) => (
                  <Text key={index}
                    p="1rem 1.5rem"
                    color={'white'}
                    textAlign="left"
                    borderBottom="1px solid gray"
                    w="100%"
                    cursor="pointer"
                    _hover={{ bg: 'gray.700' }}
                    _last={{ borderBottom: 'none' }}
                    onClick={() => setSelectedUsers(selectedUsers.filter((user) => user !== selectedUser))}
                  >
                    {selectedUser.name}
                  </Text>
                ))}
            </UnorderedList>
          </Flex>
        </HStack>
        {loading.users && <CircularProgress isIndeterminate color="green.300" position="absolute"
          top="50%" left="50%" transform="translate(-50%, -50%)"
        />}
      </Flex>

      <Button bg={'green.700'} color="white"
        _hover={{ bg: 'green.600' }} _active={{ bg: 'green.500' }}
        alignSelf="flex-end" w="min-content"
        mt={8} onClick={() => {
          api.post(`/polls/${uuid}/voters`, selectedUsers.map((user) => ({
            name: user.name,
            cellphone: user.cellphone
          })))
            .then(() => navigate('/admin/votacoes'));
        }}>Autorizar votantes</Button>
    </Flex>
  );
};
