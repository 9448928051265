import { Flex, Stack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { Serie } from '@customTypes/Serie';
import { SerieCard } from '@molecules/Card';
import { getSeries } from '@services/api';

export const HomeCardList = () => {
  const [series, setSeries] = useState<Serie[]>([]);

  useEffect(() => {
    getSeries()
      .then(series => {
        setSeries(series.data);
      });
  }, []);

  return (
    <Stack
      bg="#1b1b1d"
      p={8}>
      <Text
        fontSize={{ base: '1.2rem', md: '2rem' }}
        fontWeight="bold"
        textAlign="center"
        color="white">
          assista nossos cultos
      </Text>
      <Flex
        p={50}
        w="full"
        h={56}
        alignItems="center"
        justifyContent="center"
      >
        {series.map((serie, index) => (
          <SerieCard key={'serie-' + index} serie={serie} />
        ))}
      </Flex>
    </Stack>
  );
};
