import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    brand: {
      300: '#E94124',
      600: '#68E55E',
      700: '#168264',
      900: '#121212'
    },
    neutral: {
      50: '#F2F2F2',
      100: '#DBDBDB',
      200: '#C4C4C4',
      300: '#ADADAD',
      400: '#969696',
      500: '#808080',
      600: '#666666',
      700: '#4D4D4D',
      800: '#333333',
      900: '#1A1A1A'
    },
    green: {
      50: '#ECFAEB',
      100: '#C9F0C7',
      200: '#A7E6A3',
      300: '#84DC7E',
      400: '#62D35A',
      500: '#3FC936',
      600: '#33A12B',
      700: '#267920',
      800: '#195016',
      900: '#0D280B'
    },
    teal: {
      50: '#E9FBF6',
      100: '#C2F5E7',
      200: '#9BEED7',
      300: '#73E7C7',
      400: '#4CE1B7',
      500: '#25DAA8',
      600: '#1EAE86',
      700: '#168264',
      800: '#0F5743',
      900: '#072C22'
    },
    red: {
      50: '#FCE9E8',
      100: '#F7C2BF',
      200: '#F29C97',
      300: '#ED756E',
      400: '#E84E45',
      500: '#E3271C',
      600: '#B51F17',
      700: '#881711',
      800: '#5B100B',
      900: '#2D0806'
    },
    cyan: {
      50: '#E9F8FB',
      100: '#C2EBF5',
      200: '#9ADEEE',
      300: '#73D1E8',
      400: '#4CC4E1',
      500: '#24B7DB',
      600: '#1D93AF',
      700: '#166E83',
      800: '#0F4957',
      900: '#07252C'
    },
    orange: {
      50: '#FDEFE7',
      100: '#FAD2BD',
      200: '#F6B492',
      300: '#F39768',
      400: '#EF7A3D',
      500: '#EC5D13',
      600: '#BD4A0F',
      700: '#8E380B',
      800: '#5E2508',
      900: '#2F1304'
    },
    yellow: {
      50: '#FEF8E6',
      100: '#FDEDB9',
      200: '#FCE18C',
      300: '#FBD560',
      400: '#FAC933',
      500: '#F9BD06',
      600: '#C79705',
      700: '#957104',
      800: '#644C02',
      900: '#322601'
    }
  },
  styles: {
    global: {
      '*': {
        boxSizing: 'border-box',
        padding: 0,
        margin: 0
      },
      body: {
        fontFamily: 'Prompt, sans-serif'
      }
    }
  },
  components: {
    Heading: {
      fontFamily: 'Rasa, serif'
    }
  }
});
