import { Button, Flex, Input, InputGroup, InputLeftAddon, Text, useToast, VStack } from '@chakra-ui/react';
import { FormEvent } from 'react';
import ReactInputMask from 'react-input-mask';
import { useNavigate, useParams } from 'react-router-dom';

import { api } from '@services/api';

export const IdentifyVoter = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const data = {
      name: formData.get('name'),
      cellphone: formData.get('cellphone')?.toString().replace(/\D/g, '')
    };

    api.post(`/polls/${uuid}/authorize`, data).then(() => {
      navigate(`/votacoes/${uuid}/votar`, { state: { voter: data } });
    }).catch((error) => {
      toast({
        title: 'Erro',
        description: error.response?.data?.message || 'Ocorreu um erro ao tentar autorizar o voto',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    });
  };

  return (
    <Flex justify="center" align="center" flex="1">

      <VStack maxW="600px" spacing="4" p="8">
        <Text fontSize="2xl" fontWeight="bold" textAlign="center">
        Identifique-se
        </Text>
        <Text fontSize="md" textAlign="center">
        Para votar, você precisa se identificar. Para isso, basta informar seu primeiro nome e o número de celular cadastrado.
        </Text>
        <form onSubmit={onSubmit}>
          <VStack spacing={4}>
            <Input name="name" placeholder="Nome" accept='' />
            <InputGroup>
              <InputLeftAddon bg="neutral.800">+55</InputLeftAddon>
              <Input as={ReactInputMask} mask="(99) 99999-9999" name="cellphone" type='tel' placeholder='Celular' />
            </InputGroup>
            <Button type="submit" colorScheme="blue" size="lg" width="100%">Entrar</Button>
          </VStack>
        </form>
      </VStack>
    </Flex>
  );
};
