import { Button, chakra, CircularProgress, Flex, Heading, LinkBox, LinkOverlay, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { Poll } from '@customTypes/Poll';
import { usePageTitle } from '@hooks/usePageTitle';
import { PollButtonsStack } from '@molecules/IconButtonsStack';
import { api } from '@services/api';

export const ListPolls = () => {
  usePageTitle('Lista de votações');

  const [polls, setPolls] = useState<Poll[]>([]);
  const [loading, setLoading] = useState(false);

  const CFaPlus = chakra(FaPlus);

  useEffect(() => {
    setLoading(true);
    api.get('/polls')
      .then((response) => setPolls(response.data?.data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Flex
      p="2rem 2rem"
      borderRadius="1rem"
      flex={1}
      bg={'gray.900'}
      height="fill"
      direction="column"
      alignItems="stretch"
    >
      <Flex justify="space-between">
        <Heading color={'white'}>Lista de votações</Heading>
        <Button w="3rem" h="3rem" borderRadius="50%" color="white" bg="blackAlpha.800"
          _hover={{ bg: 'blackAlpha.500' }} _active={{ bg: 'blackAlpha.200' }}
          fontSize="lg" fontWeight="bold" as={Link} to="/admin/votacoes/nova"
        ><CFaPlus /></Button>
      </Flex>

      <Flex flex={1} direction="column" mt={8} minH="400px" pos="relative">
        <VStack spacing={4}>
          {polls.map((poll) => (
            <LinkBox key={poll.uuid}
              borderWidth="1px"
              rounded={'xl'}
              width="100%"
              p="1.5rem 2rem"
              bg={'gray.800'}
              flex="1"
              color={'white'}
            >
              <Flex direction="row">
                <Flex flex={1} direction="column">
                  <Text fontSize=".7rem" color={'gray.100'}>
                    {`Created by ${poll.createdBy} at ${poll.createdAt}`}
                  </Text>
                  <Heading color={'white'} size="lg" my="2">
                    <LinkOverlay as={Link} to={`/admin/votacoes/${poll.uuid}`}
                      state={{ poll }}
                    >
                      {poll.title}
                    </LinkOverlay>
                  </Heading>
                  <Text>
                    {poll.description}
                  </Text>
                </Flex>
                <Flex direction="row" alignItems="center">
                  <PollButtonsStack poll={poll} />
                </Flex>
              </Flex>
            </LinkBox>
          ))}
        </VStack>
        {loading && <CircularProgress isIndeterminate color="green.300" position="absolute"
          top="50%" left="50%" transform="translate(-50%, -50%)"
        />}
      </Flex>
    </Flex>
  );
};
