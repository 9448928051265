import { Flex, Stack } from '@chakra-ui/react';
import { FormEventHandler, PropsWithChildren } from 'react';

type AuthTemplateProps = PropsWithChildren<{
  handleSubmit: FormEventHandler<HTMLDivElement>;
}>;

export const AuthTemplate = ({ children, handleSubmit }: AuthTemplateProps) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100vw"
      backgroundImage={'https://images.pexels.com/photos/2599537/pexels-photo-2599537.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'}
      userSelect="none"
    >
      <Flex
        as="form"
        direction="column"
        w="100%"
        maxW={{ base: '90%', md: '540px' }}
        bg="white"
        p="3rem 2rem"
        borderRadius="40"
        boxShadow="md"
        onSubmit={handleSubmit}
        zIndex="1"
      >
        <Stack spacing={4}>
          {children}
        </Stack>
      </Flex>
    </Flex>
  );
};
