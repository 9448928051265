import { AuthProvider } from '@contexts/auth';
import { checkWebpSupport } from '@utils';

import { Router } from './routes';

export const App = () => {
  checkWebpSupport();

  return (
    <AuthProvider>
      <Router />
    </AuthProvider>
  );
};
