import { chakra, Input, InputGroup, InputGroupProps, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { useState } from 'react';
import { FaEye, FaEyeSlash, FaLock } from 'react-icons/fa';

type PasswordInputProps = InputGroupProps & {
  hideIcon?: boolean;
  mb?: string;
};

export const PasswordInput = (props: PasswordInputProps) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const CFaLock = chakra(FaLock);
  const CFaEye = chakra(FaEye);
  const CFaEyeSlash = chakra(FaEyeSlash);

  return (
    <InputGroup size='md' {...props}>
      {
        !props.hideIcon && (
          <InputLeftElement>
            <CFaLock color='grey' />
          </InputLeftElement>)}
      <Input
        pr='4.5rem'
        type={show ? 'text' : 'password'}
        placeholder='Senha'
        aria-label='Senha'
        name='password'
      />
      <InputRightElement width='4.5rem'>
        {show
          ? <CFaEyeSlash
            h='1.5rem'
            onClick={handleClick}
            cursor="pointer"
            color="gray.500"
            _hover={{ filter: 'brightness(40%)' }}
          />
          : <CFaEye
            h='1.5rem'
            onClick={handleClick}
            cursor="pointer"
            color="gray.500"
            _hover={{ filter: 'brightness(40%)' }}
          />
        }
      </InputRightElement>
    </InputGroup>
  );
};
