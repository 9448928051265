import { Button, CircularProgress, Flex, Heading, HStack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { User } from '@customTypes/User';
import { FileDropzone } from '@organisms/FileDropzone';
import { api } from '@services/api';

export const CreateUsersBulk = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClear = () => {
    setUsers([]);
  };

  const handleUpload = () => {
    setLoading(true);
    api.post('/users/bulk', users)
      .then(() => {
        setUsers([]);
        setLoading(false);
        navigate('/admin/users');
      });
  };

  return (
    <Flex
      p="2rem 2rem"
      borderRadius="1rem"
      flex={1}
      bg={'gray.900'}
      color="white"
      direction="column"
      alignItems="flex-start"
    >
      <Heading as="h1" size="lg" mb="1rem">
        Importar usuários
      </Heading>
      <Text mb="8">
        Para importar usuários, você deve enviar um arquivo CSV com os dados dos usuários. O arquivo deve conter as seguintes colunas:&nbsp;
        <Text as="span" fontWeight="bold">name</Text>,&nbsp;
        <Text as="span" fontWeight="bold">username</Text>,&nbsp;
        <Text as="span" fontWeight="bold">email</Text>,&nbsp;
        <Text as="span" fontWeight="bold">cellphone</Text>,&nbsp;
        <Text as="span" fontWeight="bold">password</Text>.
      </Text>

      {/* <Text my="1rem">
        Você pode baixar um arquivo CSV de exemplo <Link to="/download/users.csv" download>
          <Text color="teal.100" as="span" _hover={{ textDecoration: 'underline' }}>clicando aqui</Text></Link>.
      </Text> */}
      <FileDropzone users={users} setUsers={setUsers} />
      <HStack spacing="6" mt="2rem" justify="flex-end" w="100%">
        {users.length > 0 && (
          <Button bg="red.800" _hover={{ bg: 'red.700' }} size="lg"
            onClick={handleClear}>Limpar</Button>
        )}
        <Button bg="green.800" _hover={{ bg: 'green.700' }} size="lg"
          onClick={handleUpload}>Importar</Button>
      </HStack>

      {loading && <CircularProgress isIndeterminate color="green.300" position="absolute"
        top="50%" left="50%" transform="translate(-50%, -50%)"
      />}
    </Flex>
  );
};
