import { Collapse, Flex, Icon, Link, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { IoChevronDown } from 'react-icons/io5';

import { NavItem } from '@molecules/Nav';
import { MobileSubNav } from '@molecules/SubNav';

export const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        px={8}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none'
        }}>
        <Text
          fontWeight={600}
          color={'gray.200'}>
          {label}
        </Text>
        {children && (
          <Icon
            as={IoChevronDown}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            color={'gray.200'}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0' }}>
        <MobileSubNav>{children ?? []}</MobileSubNav>
      </Collapse>
    </Stack>
  );
};
