import { useWebPSupportCheck } from 'react-use-webp-support-check';

export const checkWebpSupport = () => {
  const body = document.querySelector('body');

  const webpSupported = body?.classList.contains('webp-supported');

  if (!webpSupported) {
    const webpSupportCheck = useWebPSupportCheck();

    if (webpSupportCheck) {
      body?.classList.add('webp-supported');
    }

    return webpSupportCheck;
  }

  return webpSupported;
};

export * from './RequireAuth';
