import { Stack } from '@chakra-ui/react';

import { MobileNavItem } from '@atoms/NavItem';

import { NAV_ITEMS } from '.';

export const MobileNav = () => {
  return (
    <Stack
      bg={'gray.800'}
      py={4}
      w="100%"
      pos="fixed"
      overflowY={'auto'}
      maxHeight={'calc(100vh-4rem)'}
      display={{ md: 'none' }}
      zIndex={50}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};
