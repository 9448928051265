import { Flex, Text } from '@chakra-ui/react';

import { FadeSlider } from '@molecules/Slider';

export const HeadingSlider = () => {
  return (
    <>
      <FadeSlider />
      <Flex
        w="full"
        h={{ base: 'calc(100vh - 4rem)', md: '100vh' }}
        align="center"
        justify="center"
        pos="relative"
        flexDir="column"
        top={0}
        bgColor="#1A1A1AA0"
        px={10}
        zIndex={-5}>
        <Text
          fontSize={{ base: '2.3rem', md: '4rem' }}
          fontWeight="bold"
          textAlign="center"
          color="white"
          fontFamily="Lustria">
          A comunidade da cruz de Cristo
        </Text>
      </Flex>
    </>
  );
};
