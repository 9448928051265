import { Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { HeaderWithoutNav } from '@organisms/Header';

export const PollsPage = () => {
  return (
    <Flex direction="column" minH="100vh" bg="neutral.900">
      <HeaderWithoutNav />
      <Flex w="100vw" color="white" direction="column" flex="1">
        <Outlet />
      </Flex>
    </Flex>
  );
};

export * from './IdentifyVoter';
export * from './Vote';
