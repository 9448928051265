import { Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

type DashboardCardProps = {
  children: ReactNode;
};

export const DashboardCard = ({ children }: DashboardCardProps) => {
  return (
    <Flex
      p="2rem 2rem"
      borderRadius="1rem"
      _last={{ mr: '0' }}
      minH="25rem"
      bg={'gray.900'}
      flex="1"
      color={'white'}
      mr="1rem"
    >
      { children }
    </Flex>
  );
};
