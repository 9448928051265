import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Poll, PollChoice } from '@customTypes/Poll';
import { usePageTitle } from '@hooks/usePageTitle';
import { api } from '@services/api';

export const Vote = () => {
  const { uuid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [poll, setPoll] = useState<Poll>();
  const [selectedChoice, setSelectedChoice] = useState<PollChoice>({} as PollChoice);

  usePageTitle(`Votar ${poll ? 'em ' + poll.title : ''}`);

  const data = {
    name: location.state?.voter?.name,
    cellphone: location.state?.voter?.cellphone
  };

  useEffect(() => {
    api.get(`/polls/${uuid}`).then((response) => {
      setPoll(response.data?.data);
    });
  }, [uuid]);

  const handleVote = (optionId?: number) => {
    if (optionId) {
      api.post(`/polls/${uuid}/choices/${optionId}/vote`, data).then((response) => {
        console.log(response);
        navigate(`/votacoes/${uuid}/confirmado`);
      }).catch((error) => {
        console.log(error);
      });
    }
  };

  return (
    <Flex justify="center" align="center" flex="1">
      <VStack maxW="600px" w="100%" spacing="4" p="8">
        <Text fontSize="2xl" fontWeight="bold" textAlign="center">
          {poll?.title}
        </Text>
        <Text fontSize="md" textAlign="center">
          {poll?.description}
        </Text>
        <VStack spacing={4} w="100%">
          {poll?.choices
            .filter((choice) => choice.choice !== '' && choice.id)
            .map((choice) => (
              <Button variant="solid" bg={selectedChoice.id === choice.id ? 'blue.700' : 'gray.800'}
                _hover={{ filter: 'brightness(1.3)' }} _active={{ bg: 'brightness(1.8)' }}
                p="8" w="100%" key={choice.id} onClick={() => setSelectedChoice(choice)}>
                <Text fontSize="xl" fontWeight="bold">
                  {choice.choice}
                </Text>
              </Button>
            ))}
        </VStack>
        <Button variant="solid" bg="green.800" _hover={{ bg: 'green.600' }} _active={{ bg: 'green.500' }}
          p="8" w="100%" onClick={() => handleVote(selectedChoice.id)}>
            Votar
        </Button>
      </VStack>

    </Flex>
  );
};
