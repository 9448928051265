import axios from 'axios';

import config from '@app/config.json';
import { Serie } from '@customTypes/Serie';
import { PaginationOptions } from '@molecules/Pagination';

type GetSeriesOptions = {
  page?: number;
  limit?: number;
};

const getToken = () => {
  const storedToken = localStorage.getItem('@Primeira:token');
  const token = storedToken ? JSON.parse(storedToken) : null;

  if (token) {
    return 'Bearer ' + token;
  }

  return '';
};

export const api = axios.create({
  baseURL: config.API_ENDPOINT + '/primeira-api/api/v1',
  timeout: 3000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: getToken()
  }
});

type GetSeriesResponse = {
  data: Serie[];
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  }
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    links: PaginationOptions[];
  }
};

export const getSeries = (options?: GetSeriesOptions): Promise<GetSeriesResponse> => {
  return api.get('/series', { params: options })
    .then(response => response.data)
    .catch(() => {
      return { data: [] };
    });
};
