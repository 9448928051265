import { Flex, Heading } from '@chakra-ui/react';

import { IdentifyVoter, PollsPage, Vote } from '@pages/polls';

export const pollsRoutes = (): object => {
  return {
    path: 'votacoes',
    element: <PollsPage />,
    children: [
      {
        path: ':uuid',
        element: <IdentifyVoter />
      },
      {
        path: ':uuid/votar',
        element: <Vote />
      },
      {
        path: ':uuid/confirmado',
        element: (
          <Flex justify="center" align="center" flex="1">
            <Heading size="lg">
            Votou com sucesso
            </Heading>
          </Flex>
        )
      }
    ]
  };
};
