import { Button, chakra, CircularProgress, Flex, Heading, HStack, LinkBox, LinkOverlay, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaBuffer, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { User } from '@customTypes/User';
import { usePageTitle } from '@hooks/usePageTitle';
import { UserIconButtonsStack } from '@molecules/IconButtonsStack';
import { api } from '@services/api';

export const ListUsers = () => {
  usePageTitle('Lista de usuarios');

  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);

  const CFaPlus = chakra(FaPlus);
  const CFaBuffer = chakra(FaBuffer);

  useEffect(() => {
    setLoading(true);
    api.get('/users')
      .then((response) => setUsers(response.data?.data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Flex
      p="2rem 2rem"
      borderRadius="1rem"
      flex={1}
      bg={'gray.900'}
      height="fill"
      direction="column"
      alignItems="stretch"
    >
      <Flex justify="space-between">
        <Heading color={'white'}>Lista de usuarios</Heading>
        <HStack spacing={4}>
          <Button w="3rem" h="3rem" borderRadius="50%" color="white" bg="blackAlpha.800"
            _hover={{ bg: 'blackAlpha.500' }} _active={{ bg: 'blackAlpha.200' }}
            fontSize="lg" fontWeight="bold" as={Link} to="/admin/usuarios/novo"
          ><CFaPlus /></Button>
          <Button w="3rem" h="3rem" borderRadius="50%" color="white" bg="blackAlpha.800"
            _hover={{ bg: 'blackAlpha.500' }} _active={{ bg: 'blackAlpha.200' }}
            fontSize="lg" fontWeight="bold" as={Link} to="/admin/usuarios/novos"
          ><CFaBuffer /></Button>
        </HStack>
      </Flex>

      <Flex flex={1} direction="column" mt={8} minH="400px" pos="relative">
        <VStack spacing={4}>
          {users.map((user) => (
            <LinkBox key={user.username}
              borderWidth="1px"
              rounded={'xl'}
              width="100%"
              p="1.5rem 2rem"
              bg={'gray.800'}
              flex="1"
              color={'white'}
            >
              <Flex direction="row">
                <Flex flex={1} direction="column">
                  <Heading color={'white'} size="lg" my="2">
                    <LinkOverlay as={Link} to={`/admin/usuarios/${user.username}`}
                      state={{ user }}
                    >
                      {user.name}
                    </LinkOverlay>
                  </Heading>
                  <Text>
                    {user.email}
                  </Text>
                </Flex>
                <Flex direction="row" alignItems="center">
                  <UserIconButtonsStack user={user} />
                </Flex>
              </Flex>
            </LinkBox>
          ))}
        </VStack>
        {loading && <CircularProgress isIndeterminate color="green.300" position="absolute"
          top="50%" left="50%" transform="translate(-50%, -50%)"
        />}
      </Flex>
    </Flex>
  );
};
