import { Flex, Link, Popover, PopoverContent, PopoverTrigger, Stack } from '@chakra-ui/react';

import { NavItem } from '@molecules/Nav';
import { DesktopSubNav } from '@molecules/SubNav';

export const DesktopNavItem = (props: NavItem) => {
  return (
    <Flex key={props.label} h="100%"
      rounded={'md'}
      transition={'background 0.2s ease-in-out'}
      _hover={{
        backgroundColor: '#121212BB'
      }}>
      <Popover trigger={'hover'} placement={'bottom-start'}>
        <PopoverTrigger>
          <Link
            display='flex'
            alignItems="center"
            p={4}
            href={props.href ?? '#'}
            fontSize={'sm'}
            fontWeight={300}
            color={'gray.200'}
            _hover={{
              textDecoration: 'none',
              color: 'white'
            }}>
            {props.label.toUpperCase()}
          </Link>
        </PopoverTrigger>

        {props.children && (
          <PopoverContent
            border={0}
            boxShadow={'xl'}
            bg={'gray.800'}
            p={4}
            rounded={'xl'}
            minW={'sm'}>
            <Stack>
              {props.children.map((child) => (
                <DesktopSubNav key={child.label} {...child} />
              ))}
            </Stack>
          </PopoverContent>
        )}
      </Popover>
    </Flex>
  );
};
