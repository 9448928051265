import { Button, Flex, FormControl, FormLabel, Heading, Input, useToast, VStack } from '@chakra-ui/react';
import { ChangeEvent, FormEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PollChoice } from '@customTypes/Poll';
import { api } from '@services/api';

export const CreatePoll = () => {
  const [choices, setChoices] = useState<PollChoice[]>([{ choice: '' }]);
  const [fields, setFields] = useState({
    title: '',
    description: ''
  });

  const { uuid } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (uuid) {
      api.get(`/polls/${uuid}`).then((response) => {
        setFields({
          title: response.data?.data?.title,
          description: response.data?.data?.description
        });
        setChoices(response.data?.data?.choices);
      });
    }
  }, [uuid]);

  const handleAddOption = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newChoices = [...choices];
    newChoices[index].choice = event.target.value;

    if (event.target.value === '' && choices.length > 1) {
      newChoices.splice(index, 1);
    } else if (index === choices.length - 1) {
      newChoices.push({ choice: '' });
    }

    setChoices(newChoices);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newChoices = choices.filter((choice) => choice.choice !== '' || (choice.choice === '' && choice.id));

    const formData = new FormData(e.currentTarget);

    const data = {
      title: formData.get('title'),
      description: formData.get('description'),
      choices: newChoices
    };

    api.post('/polls', data).then((response) => {
      console.log(response.data);
      navigate(`/admin/votacoes/${response.data.data.uuid}/votantes`);
    }).catch((error) => {
      toast({
        title: 'Erro',
        description: error.response?.data?.message || 'Ocorreu um erro ao tentar criar a votação',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    });
  };

  const handleReset = () => {
    setChoices([{ choice: '' }]);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      event.preventDefault();
      event.stopPropagation();

      const inputs = document.querySelectorAll('input');
      const index = Array.from(inputs).findIndex(input => input === document.activeElement);
      inputs[index + 1].focus();
    }
  };

  return (
    <Flex
      p="2rem 2rem"
      borderRadius="1rem"
      flex={1}
      bg={'gray.900'}
      color="white"
      direction="column"
      alignItems="flex-start"
    >
      <form style={{ width: '100%' }} onSubmit={handleSubmit} onReset={handleReset}>
        <VStack spacing={8} w="100%" alignItems="flex-start">
          <Heading color="white">{uuid ? 'Editar' : 'Criar'} Votação</Heading>

          <FormControl isRequired id="title">
            <FormLabel fontSize="xl">Título</FormLabel>
            <Input placeholder='Título' fontSize="xl" name="title" value={fields.title} />
          </FormControl>

          <FormControl id="description">
            <FormLabel fontSize="xl">Descrição</FormLabel>
            <Input placeholder='Descrição' fontSize="xl" name="description" value={fields.description} />
          </FormControl>

          <FormControl id="choices">
            <FormLabel fontSize="xl">Opções</FormLabel>
            <VStack spacing={2} w="100%" alignItems="flex-start"
              onKeyDown={handleKeyDown}>
              {choices.map((choice, index) => (
                <Input key={index} placeholder={`Opção ${index + 1}`}
                  fontSize="xl" value={choice.choice} onChange={(e) => handleAddOption(e, index)} />
              ))}
            </VStack>
          </FormControl>

          <Flex w="100%" justify="flex-end">
            <Button mr="4" type="reset" colorScheme="red" size="lg">Cancelar</Button>
            <Button type="submit" colorScheme="blue" size="lg">Criar</Button>
          </Flex>
        </VStack>
      </form>
    </Flex>
  );
};
